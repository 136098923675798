
.top-bar {
    float: right;
}
.login-no-auth {
    padding-top: 25vh;
    display: flex;
    justify-content: center;
    font-family: Roboto, sans-serif;

    .button-div {
        margin-top: 15px;
        display: flex;
        justify-content: center;
    }
    .login-title {
        margin-top: 15px;
        color: #03A8BB !important;
    }
    .logo-img {
        display: flex;
        justify-content: center;
    }
}
img.nav-logo {
    position: absolute;
    left: 0;
    top: 12px;
    cursor: pointer;
}
.contentarea {
    .client-company-selector {
        .ant-radio-button-wrapper-checked {
            background:#828282 !important;
            border-color: #828282 !important;
        }
        .client-company-title-space {
            height: 50px;
        }
        .client-company-top-space {
            height: 25px;
        }
        .client-company-bottom-space {
            height: 25px;
        }
    }
}

.ant-comment {
    padding: 10px;
}

.hotspot-new{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: green;
  }

  .hotspot-new.selected{
    background-color: yellow;
  }

  .hotspot-load{
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background-color: blue;
  }

  .hotspot-load.selected{
    background-color: red;
  }


  .hotspot-buttons {
      margin-top: 5px;
      display: flex;

      .addButton{
          margin-left: auto;
          order: 2;
      }
      .removeButton{
        margin-left: 5px;
        order: 2;
    }
      
  }

  .hidden {
      display: none !important;
  }

  .hide-hotspots-button{
      float: right !important;
      margin-right: 5px;
  }